import React, { PureComponent } from 'react';

class TileGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.mask = React.createRef();
    this.imageRef = React.createRef();
    this.image1Ref = React.createRef();
    this.image2Ref = React.createRef();

    this.state = {
      remainder: 0,
      imageAspectRatio: '',
      image1AspectRatio: '',
      image2AspectRatio: '',
    };
  }

  componentDidMount() {
    const remainder = this.props.items.length - 3;
    this.setState({ remainder });
    this.calculateImgAspectRatio();
    this.calculateImg1AspectRatio();
    this.calculateImg2AspectRatio();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.items.length === 1 &&
      this.props.top > 0 &&
      this.props.top < 100 &&
      this.mask.current
    ) {
      this.mask.current.click();
    }

    if (prevProps.items !== this.props.items) {
      this.calculateImgAspectRatio();
      this.calculateImg1AspectRatio();
      this.calculateImg2AspectRatio();
    }
  }

  calculateImgAspectRatio = () => {
    if (this.imageRef.current) {
      const imageSrc = this.imageRef.current.src;

      const newImg = new Image();
      newImg.src = imageSrc;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ imageAspectRatio: aspectRatio });
      };
    }
  };

  calculateImg1AspectRatio = () => {
    if (this.image1Ref.current) {
      const img1Src = this.image1Ref.current.src;

      const newImg = new Image();
      newImg.src = img1Src;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ image1AspectRatio: aspectRatio });
      };
    }
  };
  calculateImg2AspectRatio = () => {
    if (this.image2Ref.current) {
      const img2Src = this.image2Ref.current.src;

      const newImg = new Image();
      newImg.src = img2Src;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ image2AspectRatio: aspectRatio });
      };
    }
  };

  showMask = () => {
    return this.state.remainder > 0 ? (
      <div className="mask higher">+{this.state.remainder}</div>
    ) : null;
  };

  showItem = (item) => {
    return item.type === 'image' ? (
      <>
        <img
          src={item.src}
          alt="my pic"
          className="pic"
          ref={this.imageRef}
          style={{
            aspectRatio: this.state.imageAspectRatio,
          }}
          onClick={(e) => this.props.onShowBigImg(e, item)}
        />
      </>
    ) : (
      <>
        <div
          ref={this.mask}
          className="mask"
          onClick={(e) => this.props.onShowBigImg(e, item)}
        >
          <img
            className="img"
            src="/img/play-light-2-pt.svg"
            alt="Play Video"
            ref={this.image1Ref}
            style={{
              aspectRatio: this.state.image1AspectRatio,
            }}
          />
        </div>
        <img
          className="pic"
          src={item.videoThumbnailUrl}
          alt="Video Thumbnail"
          ref={this.image2Ref}
          style={{
            aspectRatio: this.state.image2AspectRatio,
            width: '100%',
            height: '100%',
            maxHeight: '400px',
            objectFit: 'contain',
            objectFit: 'contain !important',
          }}
        />
      </>
    );
  };

  triggerShowBig = (e, file) => {
    this.props.onShowBigImg(e, { type: file.type, src: file.src });
  };

  render() {
    const { items } = this.props;
    return (
      <>
        {items.length > 0 && (
          <div className="gallery tile">
            <div className="firstTile">{this.showItem(items[0])}</div>
            {items.length > 1 && (
              <div className="tileRow">
                <div className="secondTile">{this.showItem(items[1])}</div>
                {items.length > 2 && (
                  <div
                    className="thirdTile"
                    onClick={(e) => this.props.onShowBigImg(e, items[2])}
                  >
                    {this.showMask()}
                    {this.showItem(items[2])}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default TileGallery;
