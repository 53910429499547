import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Client from './pages/clients';
import DeletedClient from './pages/deleted-clients';
import Tenant from './pages/tenant';
import DeletedTenant from './pages/deleted-tenant';
import News from './pages/news';
import DeletedNews from './pages/deleted-news';
import CreateNews from './pages/create-news';
import EditNews from './pages/edit-news';
import NewsClient from './pages/news-client';
import DeletedNewsClient from './pages/deleted-news-client';
import NewsFeeds from './pages/newsfeed';
import TestApp from "./pages/test";
import ProtectedRoute from './protected.route.js';
import Groups from "./pages/groups";
import Log from './pages/log';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/clients" component={Client} />
          <ProtectedRoute exact path="/groups" component={Groups} />
          <ProtectedRoute
            exact
            path="/clients/deleted"
            component={DeletedClient}
          />
          <ProtectedRoute
            exact
            path="/clients/:clientId/tenants"
            component={Tenant}
          />
          <ProtectedRoute
            exact
            path="/clients/:clientId/tenants/deleted"
            component={DeletedTenant}
          />
          <ProtectedRoute exact path="/news" component={News} />
          <ProtectedRoute exact path="/admin/logs" component={Log} />

          <ProtectedRoute
            exact
            path="/news/:newsId/clients"
            component={NewsClient}
          />
          <ProtectedRoute
            exact
            path="/news/:newsId/clients/deleted"
            component={DeletedNewsClient}
          />
          <ProtectedRoute exact path="/news/create" component={CreateNews} />
          <ProtectedRoute exact path="/news/deleted" component={DeletedNews} />
          <ProtectedRoute exact path="/news/edit/:id" component={EditNews} />
          {/* <Route exact path="/news-feed" component={NewsFeed} /> */}
          <Route exact path="/news-feed/" component={NewsFeeds} />
        </Switch>
      </Router>
    );
  }
}

export default App;
