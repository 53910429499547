import React, { createRef, PureComponent } from 'react';

class Slider extends PureComponent {
  constructor(props) {
    super(props);
    this.videoEle = React.createRef();
    this.state = {
      sliderIndex: 0,
      isPlayed: false,
      vidPlay: [],
      vidPause: [],
    };
  }
  componentDidUpdate() {
    if (this.props.items.length === 1) {
      this.setPlayPauseVideo(this.props.top);
    }
  }
  setPlayPauseVideo(top) {
    try {
      const videoUrl = this.props.items[this.state.sliderIndex].src;
      if (this.state.vidPlay.length > 0) {
        const videoIndex = this.state.vidPlay.findIndex(
          (url) => url === videoUrl,
        );
        if (videoIndex !== -1) {
          this.setState(
            {
              isPlayed: true,
            },
            () => {
              this.playVideo(top);
            },
          );
        }
      }
      if (this.state.vidPause.length > 0) {
        const videoIndex = this.state.vidPause.findIndex(
          (url) => url === videoUrl,
        );
        if (videoIndex !== -1) {
          this.setState(
            {
              isPlayed: false,
            },
            () => {
              this.pauseVideo(top);
            },
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    try {
      this.setState(
        {
          sliderIndex: this.props.index,
        },
        () => {
          if (this.props.items.length === 1) {
            this.setPlayPauseVideo(this.props.top);
          }
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  async showNext() {
    try {
      this.setState(
        (prevState) => {
          return {
            sliderIndex: prevState.sliderIndex + 1,
          };
        },
        () => {
          console.log('Index :', this.state.sliderIndex);
          console.log(this.props.items[this.state.sliderIndex]);
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  showPrevious() {
    try {
      let index = this.state.sliderIndex - 1;
      this.setState({
        sliderIndex: index,
      });
    } catch (error) {
      console.log(error);
    }
  }
  vidPause = () => {
    const videoElement = this.videoEle.current;
    if (videoElement) {
      const src = videoElement.querySelector('source')?.src;
      if (src) {
        this.setState((prevState) => {
          const updatedVidPause = [...prevState.vidPause, src];
          const updatedVidPlay = prevState.vidPlay.filter((url) => url !== src);
          return { vidPause: updatedVidPause, vidPlay: updatedVidPlay };
        });
      }
    }
  };

  vidPlay = () => {
    const videoElement = this.videoEle.current;
    if (videoElement) {
      const src = videoElement.querySelector('source')?.src;
      if (src) {
        this.setState((prevState) => {
          const updatedVidPlay = [...prevState.vidPlay, src];
          const updatedVidPause = prevState.vidPause.filter(
            (url) => url !== src,
          );
          return { vidPlay: updatedVidPlay, vidPause: updatedVidPause };
        });
      }
    }
  };

  async playVideo() {
    try {
      if (this.videoEle.current) {
        await this.videoEle.current.play();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async pauseVideo() {
    try {
      if (this.videoEle.current) {
        await this.videoEle.current.pause();
      }
    } catch (err) {
      console.log(err);
    }
  }
  showSlide(index) {
    try {
      let obj = this.props.items[index];
      return obj.type === 'image' ? <img src={obj.src} /> : '';
    } catch (error) {
      console.log(error);
    }
  }
  closeSlider() {
    try {
      this.props.onClose();
    } catch (error) {
      console.log(error);
    }
  }
  enlarge(src) {
    var win = window.open(src, '_blank');
    win.focus();
  }
  topBtns(src) {
    try {
      return (
        <>
          {/* <button className="closebtn" onClick={() => this.closeSlider()}>
            <img src="/img/top-left-arrow.svg" />
          </button>
          <button className="enlargeBtn" onClick={() => this.enlarge(src)}>
            <img src="/img/top-right-arrow.svg" />
          </button> */}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  }

  prevBtn() {
    try {
      return this.state.sliderIndex > 0 ? (
        <button className="prev" onClick={() => this.showPrevious()}>
          <span>
            {' '}
            <img
              src="/img/arrow-left.svg"
              width="35"
              height="35"
              className="bottomleft"
            />
          </span>
        </button>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }

  nextBtn() {
    try {
      return this.state.sliderIndex + 1 < this.props.items.length ? (
        <button className="next" onClick={() => this.showNext()}>
          <span>
            {' '}
            <img
              src="/img/arrow-right.svg"
              width="35"
              height="35"
              className="bottomright"
            />
          </span>
        </button>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }

  handleOpenInNewTab(url) {
    window.open(url, '_blank');
  }

  // handleOpenInFullScreen() {
  //   const videoElement = this.videoEle.current;
  //   if (videoElement) {
  //     if (videoElement.requestFullscreen) {
  //       videoElement.requestFullscreen();
  //     } else if (videoElement.mozRequestFullScreen) {
  //       // For Firefox
  //       videoElement.mozRequestFullScreen();
  //     } else if (videoElement.webkitRequestFullscreen) {
  //       // For Chrome and Safari
  //       videoElement.webkitRequestFullscreen();
  //     } else if (videoElement.msRequestFullscreen) {
  //       // For IE/Edge
  //       videoElement.msRequestFullscreen();
  //     }
  //   }
  // }

  render() {
    return (
      <div className="slider">
        <div
          className="stage"
          style={{
            position: 'relative',
          }}
        >
          {this.props.items[this.state.sliderIndex].type === 'image' ? (
            <>
              <img src={this.props.items[this.state.sliderIndex].src} />
            </>
          ) : (
            <>
              <video
                onPause={this.vidPause}
                onPlay={this.vidPlay}
                width="320"
                height="240"
                controls
                ref={this.videoEle}
                key={this.props.items[this.state.sliderIndex].id}
                autoPlay
                muted={this.props.items.length === 1}
              >
                <source
                  src={this.props.items[this.state.sliderIndex].videoUrl}
                  type="video/mp4"
                />
              </video>
            </>
          )}

          {this.props.items[this.state.sliderIndex].type === 'video' && (
            <div
              style={{
                position: 'absolute',
                bottom: 37,
                right: 'clamp(100px, 8vw, 200px)',
                cursorL: 'pointer !important',
                zIndex: 100000,
              }}
              onClick={() =>
                this.handleOpenInNewTab(
                  this.props.items[this.state.sliderIndex].videoUrl,
                )
              }
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 32 32"
                id="i-fullscreen"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                stroke="currentcolor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                cursor="pointer"
              >
                <path d="M4 12 L4 4 12 4 M20 4 L28 4 28 12 M4 20 L4 28 12 28 M28 20 L28 28 20 28" />
              </svg>
            </div>
          )}
        </div>

        {this.prevBtn()}
        {this.nextBtn()}
        {this.topBtns(this.props.items[this.state.sliderIndex].src)}
      </div>
    );
  }
}

export default React.memo(Slider);
